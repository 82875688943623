import React from 'react';
import convert from 'convert-units';
import IDBroker from '../../../utils/id-broker';
import { SHADE_LIGHT_BLUE_COLOR } from '../../../constants';

const STYLE = {
  stroke: SHADE_LIGHT_BLUE_COLOR,
  strokeWidth: "1px"
};

const BACKGROUNDSTYLE = {
  fill:'white',
  fillOpacity:0.800000
};

const STYLE_TEXT = {
  textAnchor: "middle",
  fontSize: "12px",  
  pointerEvents: "none",

  //http://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting-using-css
  WebkitTouchCallout: "none", /* iOS Safari */
  WebkitUserSelect: "none",   /* Chrome/Safari/Opera */
  MozUserSelect: "none",      /* Firefox */
  MsUserSelect: "none",       /* Internet Explorer/Edge */
  userSelect: "none"
};

export default function Ruler3D({ layer, line }) {

  const getDistance = (v0, v1) => {
    return Math.sqrt((v0.x - v1.x) * (v0.x - v1.x) + (v0.y - v1.y) * (v0.y - v1.y));
  }

  const {v0, v1, text, space} = line;
  let length = getDistance(v0, v1);

  let textLength = (text.length + layer.unit.length) * 18;
  if(v0.y === v1.y){
    if(v0.x < v1.x){
      return (
        <g>
          <line x1={v0.x} y1={v0.y} x2={v0.x} y2={v0.y - space} style={STYLE} />
          <g transform={`translate(${v0.x}, ${v0.y - space})`}>
            <line x1={-5} y1={-5} x2={5} y2={5} style={STYLE} />
            <line x1={0} y1={0} x2={textLength > length ? 0 : (length - textLength) / 2} y2={0} style={STYLE} />
            <text x={length / 2} y="3" style={STYLE_TEXT} fill={SHADE_LIGHT_BLUE_COLOR}>{text}{layer.unit === "in" ? '"' : layer.unit}</text>
            <line x1={length} y1={0} x2={textLength > length ? length : (length + textLength) / 2} y2={0} style={STYLE} />
            <line x1={length - 5} y1={-5} x2={length + 5} y2={5} style={STYLE} />
          </g>
          <line x1={v1.x} y1={v1.y} x2={v1.x} y2={v1.y - space} style={STYLE} />
        </g>
      );
    } else {
      return (
        <g>
          <line x1={v0.x} y1={v0.y} x2={v0.x} y2={v0.y + space} style={STYLE} />
          <g transform={`translate(${v0.x}, ${v0.y + space})`}>
            <line x1={-5} y1={-5} x2={5} y2={5} style={STYLE} />
            <line x1={0} y1={0} x2={textLength > length ? 0 : -(length - textLength) / 2} y2={0} style={STYLE} />
            <text x={-length / 2} y="3" style={STYLE_TEXT} fill={SHADE_LIGHT_BLUE_COLOR}>{text}{layer.unit === "in" ? '"' : layer.unit}</text>
            <line x1={-length} y1={0} x2={textLength > length ? -length : -(length + textLength) / 2} y2={0} style={STYLE} />
            <line x1={-length - 5} y1={-5} x2={-length + 5} y2={5} style={STYLE} />
          </g>
          <line x1={v1.x} y1={v1.y} x2={v1.x} y2={v1.y + space} style={STYLE} />
        </g>
      );
    }
  } else if(v0.x = v1.x) {
    if(v0.y < v1.y){
      return (
        <g>
          <line x1={v0.x} y1={v0.y} x2={v0.x + space} y2={v0.y} style={STYLE} />
          <g transform={`translate(${v0.x + space}, ${v0.y})`}>
            <line x1={-5} y1={-5} x2={5} y2={5} style={STYLE} />
            <line x1={0} y1={0} x2={0} y2={textLength > length ? 0 : (length - textLength) / 2} style={STYLE} />
            <text x={length / 2} y={0} style={{...STYLE_TEXT, transform: 'rotate(90deg)'}} fill={SHADE_LIGHT_BLUE_COLOR}>{text}{layer.unit === "in" ? '"' : layer.unit}</text>
            <line x1={0} y1={length} x2={0} y2={textLength > length ? length : (length + textLength) / 2} style={STYLE} /> 
            <line x1={-5} y1={length - 5} x2={5} y2={length + 5} style={STYLE} />
          </g>
          <line x1={v1.x} y1={v1.y} x2={v1.x + space} y2={v1.y} style={STYLE} />
        </g>
      );
    } else {
      return (
        <g>
          <line x1={v0.x} y1={v0.y} x2={v0.x - space} y2={v0.y} style={STYLE} />
          <g transform={`translate(${v0.x - space}, ${v0.y})`}>
            <line x1={-5} y1={-5} x2={5} y2={5} style={STYLE} />
            <line x1={0} y1={0} x2={0} y2={textLength > length ? 0 : -(length - textLength) / 2} style={STYLE} />
            <text x={-length / 2} y={0} style={{...STYLE_TEXT, transform: 'rotate(90deg)'}} fill={SHADE_LIGHT_BLUE_COLOR}>{text}{layer.unit === "in" ? '"' : layer.unit}</text>
            <line x1={0} y1={-length} x2={0} y2={textLength > length ? -length : -(length + textLength) / 2} style={STYLE} /> 
            <line x1={-5} y1={-length - 5} x2={5} y2={-length + 5} style={STYLE} />
          </g>
          <line x1={v1.x} y1={v1.y} x2={v1.x - space} y2={v1.y} style={STYLE} />
        </g>
      );
    }
  }
}

