import React, { useEffect } from 'react';
import Viewer3D from './viewer3d';
import State3D from './ruler-utils/state3D';

export default function Front3D(
  { width, height, state, replaceCabinet, keyDownEnable, catalog },
  { viewer2DActions }
) {
  let sceneWidth = width;
  let sceneHeight = height;
  return (
    <>
      <svg
        width={sceneWidth}
        height={sceneHeight}
        id="front"
        style={{ position: 'absolute', display: 'none' }}
      >
        <g transform={`translate(${width/2}, ${height/2})`}>
          <State3D
            state={state}
            catalog={catalog}
            viewer2DActions={viewer2DActions}
            height={sceneHeight}
            width={sceneWidth}
          />
        </g>
      </svg>
      <Viewer3D
        state={state}
        width={width}
        height={height}
        replaceCabinet={replaceCabinet}
        keyDownEnable={keyDownEnable}
      />
    </>
  );
}
