import React from 'react';
import { GeometryUtils } from '../../../utils/export';
import convert from 'convert-units';
import Layer3D from './layer3D';

export function compareVertices(v0, v1) {
  return v0.x === v1.x ? v0.y - v1.y : v0.x - v1.x;
}

export default function Scene3D({scene, catalog, width, height}) {

  let { layers } = scene;
  let selectedLayer = layers.get(scene.selectedLayer);
  let ceilHeight = selectedLayer.ceilHeight;
  let selectedLine = selectedLayer.lines.get(selectedLayer.selected.lines.toJS()[0]);
  let vertex0 = selectedLayer.vertices.get(selectedLine.vertices.get(0));
  let vertex1 = selectedLayer.vertices.get(selectedLine.vertices.get(1));
  // if (vertex0.id === vertex1.id || GeometryUtils.samePoints(vertex0, vertex1)) return null; //avoid 0-length lines
  let { x: x1, y: y1 } = vertex0;
  let { x: x2, y: y2 } = vertex1;
  if (compareVertices(vertex0, vertex1) >= 0 && vertex0.x !== vertex1.x) {
    ({ x: x1, y: y1 } = vertex1);
    ({ x: x2, y: y2 } = vertex0);
  }
  let line_length = convert(GeometryUtils.pointsDistance(x1, y1, x2, y2)).from(scene.unit).to(scene.rulerUnit).toFixed(0);
  let scale = Math.min(width * 0.6 / line_length, height * 0.6 / ceilHeight);
  let frontRect = {width: line_length * scale, height: ceilHeight * scale};
  return (
    <g>
      {/* <polygon points={`-${frontRect.width / 2},-${frontRect.height / 2} ${frontRect.width / 2},-${frontRect.height / 2} ${frontRect.width / 2},${frontRect.height / 2} -${frontRect.width / 2},${frontRect.height / 2}`} fill="lime" /> */}
      <Layer3D key={selectedLayer.id} layer={selectedLayer} line={selectedLine} frontRect={frontRect} lineLength={line_length} ceilHeight={ceilHeight.toFixed(0)} catalog={catalog} scene={scene} scale={scale} />
    </g>
  )
}
