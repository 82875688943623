import React from 'react';
import Ruler3D from './ruler3D';
import convert from 'convert-units';
import { GeometryUtils } from '../../../utils/export';
import { returnReplaceableDeepSearchType } from '../../../components/viewer2d/utils';

const rulerSpace = 50;
const lineSpace = 25;

export default function Layer3D({
  layer,
  line,
  frontRect,
  lineLength,
  ceilHeight,
  catalog,
  scene,
  scale
}) {
  const { width, height } = frontRect;
  let vertex0 = layer.vertices.get(line.vertices.get(0));
  let vertex1 = layer.vertices.get(line.vertices.get(1));
  const l0 = { x: vertex0.x, y: vertex0.y };
  const l1 = { x: vertex1.x, y: vertex1.y };
  let lines = [],
    rulerData = [],
    baseItems = [],
    wallItems = [],
    basePosArray = [
      { x: width / 2, y: height / 2 },
      { x: -width / 2, y: height / 2 }
    ],
    wallPosArray = [
      { x: -width / 2, y: -height / 2 },
      { x: width / 2, y: -height / 2 }
    ],
    left_count = 0,
    right_count = 0;
  const isSnapped = itemRect => {
    const r2 = itemRect.rect[2];
    const r3 = itemRect.rect[3];
    let delta23 =
      GeometryUtils.verticesDistance(l0, r3) +
      GeometryUtils.verticesDistance(r3, r2) +
      GeometryUtils.verticesDistance(r2, l1) -
      GeometryUtils.verticesDistance(l1, l0);
    if (delta23 < 0.1) {
      return true;
    } else {
      return false;
    }
  };

  const getLength = (vertex0, vertex1) =>{
    return Math.sqrt(Math.pow(vertex0.x - vertex1.x, 2) + Math.pow(vertex0.y - vertex1.y, 2));
  }

  layer.items.forEach(item => {
    let val = {
      pos: { x: item.x, y: item.y },
      rotRad: (item.rotation / 180) * Math.PI
    };
    let catid = item.type;
    let cat = catalog.elements[catid];
    if (!cat) cat = catalog.elements[returnReplaceableDeepSearchType(catid)];
    let width = convert(item.properties.getIn(['width', '_length']))
      .from('in')
      .to(scene.unit);
    let height = convert(item.properties.getIn(['height', '_length']))
      .from('in')
      .to(scene.unit);
    let depth = convert(item.properties.getIn(['depth', '_length']))
      .from('in')
      .to(scene.unit);
    val.size = { width, height, depth };
    val.layoutpos = cat.info.layoutpos;
    val.is_corner = cat.info.is_corner;
    val.item = item.toJS();
    let calcrect = GeometryUtils.getCalcRectFromItem3D(val);
    if (isSnapped(calcrect)) {
      switch (calcrect.layoutpos) {
        case 'Base':
        case 'Tall':
        case 'Vanity':
        case 'Accessories':
          baseItems.push(calcrect);
          break;
        case 'Wall':
          wallItems.push(calcrect);
        default:
          break;
      }
    }
  });

  wallItems.sort((a, b) => {
    return a.pos.x - b.pos.x;
  });
  baseItems.sort((a, b) => {
    return b.pos.x - a.pos.x;
  });

  if (baseItems.length !== 0) {
    baseItems.forEach((item, idx) => {
      let distance = 0;
      if (idx === 0) {
        distance = convert(getLength(item.rect[2], l1))
          .from(scene.unit)
          .to(scene.rulerUnit);
        basePosArray.splice(basePosArray.length - 1, 0, {
          x: basePosArray[basePosArray.length - 2].x - distance * scale,
          y: height / 2
        });
        basePosArray.splice(basePosArray.length - 1, 0, {
          x:
            basePosArray[basePosArray.length - 2].x -
            convert(item.size.width)
              .from(scene.unit)
              .to(scene.rulerUnit) *
              scale,
          y: height / 2
        });
      } else {
        distance = convert(
          getLength(item.rect[2], baseItems[idx - 1].rect[3])
        )
          .from(scene.unit)
          .to(scene.rulerUnit);
        basePosArray.splice(basePosArray.length - 1, 0, {
          x: basePosArray[basePosArray.length - 2].x - distance * scale,
          y: height / 2
        });
        basePosArray.splice(basePosArray.length - 1, 0, {
          x:
            basePosArray[basePosArray.length - 2].x -
            convert(item.size.width)
              .from(scene.unit)
              .to(scene.rulerUnit) *
              scale,
          y: height / 2
        });
      }
      if(Math.abs(item.pos.x - l0.x) < Math.abs(item.pos.x - l1.x)) {
        let distLine = convert(getLength(item.rect[3], l0)).from(scene.unit).to(scene.rulerUnit);
        let itemHeight = convert(item.size.height).from(scene.unit).to(scene.rulerUnit);
        lines.push({v0: {x: -width / 2 + distLine * scale, y: height / 2}, v1: {x: -width / 2 + distLine * scale, y: height / 2 - itemHeight * scale}, text: itemHeight.toFixed(1), space: rulerSpace + distLine * scale + left_count * lineSpace});
        lines.push({v0: {x: -width / 2, y: height / 2 - itemHeight * scale}, v1: {x: -width / 2, y: -height / 2}, text: (ceilHeight - itemHeight).toFixed(1), space: rulerSpace + left_count * lineSpace});
        left_count++;
      } else {
        let distLine = convert(getLength(item.rect[2], l1)).from(scene.unit).to(scene.rulerUnit);
        let itemHeight = convert(item.size.height).from(scene.unit).to(scene.rulerUnit);
        lines.push({v0: {x: width / 2, y: -height / 2}, v1: {x: width / 2, y: height / 2 - itemHeight * scale}, text: (ceilHeight - itemHeight).toFixed(1), space: rulerSpace + right_count * lineSpace});
        lines.push({v0: {x: width / 2 - distLine * scale, y: height / 2 - itemHeight * scale}, v1: {x: width / 2 - distLine * scale, y: height / 2}, text: itemHeight.toFixed(1), space: rulerSpace + distLine * scale + right_count * lineSpace});
        right_count++;
      }
    });
  }

  if (wallItems.length !== 0) {
    wallItems.forEach((item, idx) => {
      let distance = 0;
      if (idx === 0) {
        distance = convert(getLength(item.rect[3], l0))
          .from(scene.unit)
          .to(scene.rulerUnit);
        wallPosArray.splice(wallPosArray.length - 1, 0, {
          x: wallPosArray[wallPosArray.length - 2].x + distance * scale,
          y: -height / 2
        });
        wallPosArray.splice(wallPosArray.length - 1, 0, {
          x:
            wallPosArray[wallPosArray.length - 2].x +
            convert(item.size.width)
              .from(scene.unit)
              .to(scene.rulerUnit) *
              scale,
          y: -height / 2
        });
      } else {
        distance = convert(
          getLength(item.rect[3], wallItems[idx - 1].rect[2])
        )
          .from(scene.unit)
          .to(scene.rulerUnit);
        wallPosArray.splice(wallPosArray.length - 1, 0, {
          x: wallPosArray[wallPosArray.length - 2].x + distance * scale,
          y: -height / 2
        });
        wallPosArray.splice(wallPosArray.length - 1, 0, {
          x:
            wallPosArray[wallPosArray.length - 2].x +
            convert(item.size.width)
              .from(scene.unit)
              .to(scene.rulerUnit) *
              scale,
          y: -height / 2
        });
      }
      if(Math.abs(item.pos.x - l0.x) < Math.abs(item.pos.x - l1.x)) {
        let distLine = convert(getLength(item.rect[3], l0)).from(scene.unit).to(scene.rulerUnit);
        let itemHeight = convert(item.size.height).from(scene.unit).to(scene.rulerUnit);
        lines.push({v0: {x: -width / 2, y: height / 2}, v1: {x: -width / 2, y: height / 2 - item.itemInfo.properties.altitude._length * scale}, text: item.itemInfo.properties.altitude._length.toFixed(1), space: rulerSpace + left_count * lineSpace});
        lines.push({v0: {x: -width / 2 + distLine * scale, y: height / 2 - item.itemInfo.properties.altitude._length * scale}, v1: {x: -width / 2 + distLine * scale, y: height / 2 - (item.itemInfo.properties.altitude._length + itemHeight) * scale}, text: itemHeight.toFixed(1), space: rulerSpace + + distLine * scale + left_count * lineSpace});
        lines.push({v0: {x: -width / 2, y: height / 2 - (item.itemInfo.properties.altitude._length + itemHeight) * scale}, v1: {x: -width / 2, y: -height / 2}, text: (ceilHeight - (item.itemInfo.properties.altitude._length + itemHeight)).toFixed(1), space: rulerSpace + left_count * lineSpace});
        left_count++;
      } else {
        let distLine = convert(getLength(item.rect[2], l1)).from(scene.unit).to(scene.rulerUnit);
        let itemHeight = convert(item.size.height).from(scene.unit).to(scene.rulerUnit);
        lines.push({v0: {x: width / 2, y: -height / 2}, v1: {x: width / 2, y: height / 2 - (itemHeight + item.itemInfo.properties.altitude._length) * scale}, text: (ceilHeight - (item.itemInfo.properties.altitude._length + itemHeight)).toFixed(1), space: rulerSpace + right_count * lineSpace});
        lines.push({v0: {x: width / 2 - distLine * scale, y: height / 2 - (itemHeight + item.itemInfo.properties.altitude._length) * scale}, v1: {x: width / 2 - distLine * scale, y: height / 2 - item.itemInfo.properties.altitude._length * scale}, text: itemHeight.toFixed(1), space: rulerSpace + distLine * scale + right_count * lineSpace});
        lines.push({v0: {x: width / 2, y: height / 2 - item.itemInfo.properties.altitude._length * scale}, v1: {x: width / 2, y: height / 2}, text: item.itemInfo.properties.altitude._length.toFixed(1), space: rulerSpace + right_count * lineSpace});
        right_count++;
      }
    });
  }

  lines.push({ v0: { x: width / 2, y: -height / 2 }, v1: { x: width / 2, y: height / 2 }, space: rulerSpace, text: ceilHeight, space: rulerSpace + lineSpace * right_count });
  lines.push({ v0: { x: -width / 2, y: height / 2 }, v1: { x: -width / 2, y: -height / 2 }, space: rulerSpace, text: ceilHeight, space: rulerSpace + lineSpace * left_count });

  for (let i = 0; i < wallPosArray.length - 1; i++) {
    let dist = getLength(wallPosArray[i], wallPosArray[i + 1]) / scale;
    lines.push({
      v0: wallPosArray[i],
      v1: wallPosArray[i + 1],
      space: rulerSpace,
      text: dist.toFixed(0)
    });
  }
  for (let j = 0; j < basePosArray.length - 1; j++) {
    let dist = getLength(basePosArray[j], basePosArray[j + 1]) / scale;
    lines.push({
      v0: basePosArray[j],
      v1: basePosArray[j + 1],
      space: rulerSpace,
      text: dist.toFixed(0)
    });
  }

  lines.forEach(line => {
    if (line.text !== '0' && line.text !== '0.0')
      rulerData.push(<Ruler3D line={line} layer={layer} />);
  });

  return <g opacity={layer.opacity}>{rulerData}</g>;
}
