import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import { GeometryUtils } from '../../utils/export';
import { toFixedFloat } from '../../utils/math';
import convert from 'convert-units';
import { zoom } from 'react-svg-pan-zoom';
import ToggleMeasureButton from '../button/ToggleMeasureButton';

import {
  MODE_IDLE,
  MODE_IDLE_3D,
  ARRAY_3D_MODES,
  KEYBOARD_BUTTON_CODE,
  TEXT_COLOR_NEUTRAL_5,
  MODE_FRONT_VIEW,
} from '../../constants';
import * as constants from '../../constants';

let allItemRect;
let allItemSnap;
let allLines;
let allLineRects;
let allLineSnap;
let allRect;

export default class FooterBar extends Component {
  constructor(props, context) {
    super(props, context);
    let selectedLayout = props.state.scene.selectedLayer;
    let height = props.state.scene.layers.get(selectedLayout).ceilHeight;
    let unit = props.state.scene.layers.get(selectedLayout).unit;
    this.state = {
      objectSetting: null,
      controlButton: "",
      panButton:"",
      toggleUnit: 'in',
      dragFlag: true,
      unit: unit,
      ceilFlag: false,
      ceil3DFlag: false,
      height: height,
      zoomValue: 100
    };
  }
  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
    let selectedLayout = nextProps.state.scene.selectedLayer;
    let height = nextProps.state.scene.layers.get(selectedLayout).ceilHeight;
    let unit = nextProps.state.scene.layers.get(selectedLayout).unit;
    let viewer2DValue = nextProps.state.get('viewer2D').toJS();
    let _zoomValue = parseInt(
      (viewer2DValue.a - 0.5) / constants.ZOOM_VARIABLE
    );
    if (_zoomValue > 380) {
      _zoomValue = 400;
    }
    if (_zoomValue < 35 || Number.isNaN(_zoomValue)) {
      _zoomValue = 30;
    }
    this.setState({ height: height, unit: unit, zoomValue: _zoomValue });
  }

  render() {
    let { state: globalState } = this.props;

    let layer = globalState.scene.layers.get(globalState.scene.selectedLayer);
    let selectedItems = [];
    if ( layer.selected.items.size > 0 ) {
      selectedItems = layer.selected.items.toJS();
    }
    let { projectActions, viewer3DActions, viewer2DActions, itemsActions, catalog,} = this.context;
    let { controlButton: control, panButton: pan_control, toggleUnit, dragFlag } = this.state;
    // let { x, y } = globalState.get('mouse').toJS();
    // let zoom = globalState.get('zoom');
    let mode = globalState.get('mode');
    let scene = globalState.get('scene');

    let update = (LengthInput, unitInput, type) => {

      let newLength = toFixedFloat(LengthInput);

      if(type === 0) {
        this.setState({ height: newLength });
        viewer2DActions.updateCeilHeight(newLength);
        viewer2DActions.updateCeilHeightUnit(unitInput);
        viewer3DActions.update3DCeilHeight(newLength);
        viewer3DActions.update3DCeilHeightUnit(unitInput);
      } else {
        this.setState({ height: convert(newLength).from(this.state.unit).to(unitInput) });
        this.setState({ unit: unitInput, });
        viewer2DActions.updateCeilHeight(convert(newLength).from(this.state.unit).to(unitInput));
        viewer2DActions.updateCeilHeightUnit(unitInput);
        viewer3DActions.update3DCeilHeightUnit(unitInput);
        viewer3DActions.update3DCeilHeight(convert(newLength).from(this.state.unit).to(unitInput));
      }
    };

    let prepareSnap = () => {
      allItemRect = GeometryUtils.getAllItems(scene, catalog)
      allItemSnap = GeometryUtils.getAllItemSnap(allItemRect);
      allLines = GeometryUtils.getAllLines(layer);
      allLineRects = GeometryUtils.buildRectFromLines(layer, allLines);
      allLineSnap = GeometryUtils.getAllLineSnap(allLineRects, allItemRect.cur);
      allRect = allItemRect.others.concat(allLineRects);
      allItemSnap = GeometryUtils.validateSnaps(allItemSnap, allRect);
      allLineSnap = GeometryUtils.validateSnaps(allLineSnap, allRect);
    };

    function spinDragStart(key) {
        if(dragFlag) {
          window.interval = setInterval(() => {
            window.SPKeyDown({ keyCode: key });
          },50);
        }
    }

    function spinDragStop() {
        clearInterval(window.interval);
    }

    function panDragStart(key) {
      if(dragFlag) {
        window.interval = setInterval(() => {
          window.tDKeyDown({ keyCode: key });
        },50);
      }
    }

    function panDragStop() {
        clearInterval(window.interval);
    }

    function validOutSVG(x, y) {
      const { SVGWidth, SVGHeight } = globalState.get('viewer2D').toJS();
      if( x <0 || x > SVGWidth || y < 0 || y > SVGHeight)
        return false;
      return true;
    }

    function OpenSettings(e){
      e.preventDefault();

      document.getElementById('setting_dialog').style.display = 'flex'
      // closes the selected item dialog
      // projectActions.unselectAll()
    }
    let footerview = mode === MODE_FRONT_VIEW ? 
      <div style={{ position: 'absolute', bottom: 10, display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'flex-end' }}>
        <div style = {{ display: "flex", position: 'relative', backgroundColor: 'white', height: 70, cursor: "pointer", boxShadow : '0px 3px 5px -1px rgba(156, 154, 154, 0.2), 0px 6px 10px 0px rgba(156, 154, 154, 0.39), 0px 1px 18px 0px rgba(156, 154, 154, 0.12)', borderRadius: 10, alignItems: 'center' }}>
          <S.Toggle_2D_3D
            onClick={() => {
              projectActions.setMode(MODE_IDLE);
              let layer = globalState.scene.getIn(["layers", globalState.scene.selectedLayer]);
              let sCount = layer.selected.areas.size + layer.selected.holes.size + layer.selected.items.size + layer.selected.lines.size;
              if (sCount > 1)
                projectActions.unselectAll();
              this.props.setShowProperty(true);
              this.setState({ ceil3DFlag: false });
              // document.getElementById('item_custom') && (document.getElementById('item_custom').style.display = document.getElementById('item_custom').style.display === 'flex' && 'none');
              document.getElementById('item_warning') && document.getElementById('item_custom') && (document.getElementById('item_warning').style.display = document.getElementById('item_custom').style.display === 'flex' && 'none');
            }}
          >
            <S.FooterTitle>2D</S.FooterTitle>
          </S.Toggle_2D_3D>
          <S.Toggle_2D_3D
            style={{marginLeft: 5}}
            onClick={() => {
              projectActions.setMode(MODE_IDLE_3D);
              let layer = globalState.scene.getIn(["layers", globalState.scene.selectedLayer]);
              let sCount = layer.selected.areas.size + layer.selected.holes.size + layer.selected.items.size + layer.selected.lines.size;
              if (sCount > 1)
                projectActions.unselectAll();
              this.setState({ ceilFlag: false });
            }}
          >
            <S.FooterTitle>3D</S.FooterTitle>
          </S.Toggle_2D_3D>
          <S.Toggle_2D_3D style={{backgroundColor: TEXT_COLOR_NEUTRAL_5}}>
            <S.FooterTitle>Front</S.FooterTitle>
          </S.Toggle_2D_3D>
          <S.Direction_Left_Right
            onMouseDown={() => {
              window.tDKeyDown({ keyCode: 37 });//Up key
              panDragStart(37);
              this.setState({ panButton: 'Left', dragFlag:false });

              document.addEventListener('mouseup', () => {
                panDragStop();
                this.setState({ panButton: 'Left', dragFlag:true });
              }, { once: true });
            }}
          >
            <S.ArrowIcon src="/assets/img/svg/bottombar/arrow-left.svg" />
          </S.Direction_Left_Right>
          <div>
            <S.Direction_Up_Down
              onMouseDown={() => {
                window.tDKeyDown({ keyCode: 38 });//Up key
                panDragStart(38);
                this.setState({ panButton: 'Top', dragFlag:false });

                document.addEventListener('mouseup', () => {
                  panDragStop();
                  this.setState({ panButton: 'Top', dragFlag:true });
                }, { once: true });
              }}
            >
              <S.ArrowIcon src="/assets/img/svg/bottombar/arrow-up.svg" />
            </S.Direction_Up_Down>
            <S.Direction_Up_Down
              onMouseDown={() => {
                window.tDKeyDown({ keyCode: 40 });//Up key
                panDragStart(40);
                this.setState({ panButton: 'Bottom', dragFlag:false });

                document.addEventListener('mouseup', () => {
                  panDragStop();
                  this.setState({ panButton: 'Bottom', dragFlag:true });
                }, { once: true });
              }}
            >
              <S.ArrowIcon src="/assets/img/svg/bottombar/arrow-down.svg" />
            </S.Direction_Up_Down>
          </div>
          <S.Direction_Left_Right
            onMouseDown={() => {
              window.tDKeyDown({ keyCode: 39 });//Up key
              panDragStart(39);
              this.setState({ panButton: 'Right', dragFlag:false });

              document.addEventListener('mouseup', () => {
                panDragStop();
                this.setState({ panButton: 'Right', dragFlag:true });
              }, { once: true });
            }}
          >
            <S.ArrowIcon src="/assets/img/svg/bottombar/arrow-right.svg" />
          </S.Direction_Left_Right>
          <S.Rotation_Button
            isVertical
            onMouseDown={() => {
              window.SPKeyDown({ keyCode: 87 });
              spinDragStart(87);
              this.setState({ panButton: 'spinTop', dragFlag: false });
            }}
            onMouseUp={() => {
              spinDragStop();
              this.setState({ panButton: 'spinTop', dragFlag: true });
            }}
          >
            <S.Rotation_Icon
              src="/assets/img/svg/bottombar/spin-up.svg"
              width="unset"
            />
          </S.Rotation_Button>
          <div>
            <S.Rotation_Button
              onMouseDown={() => {
                window.SPKeyDown({ keyCode: 68 });
                spinDragStart(68);
                this.setState({ panButton: 'spinRight', dragFlag: false });
              }}
              onMouseUp={() => {
                spinDragStop();
                this.setState({ panButton: 'spinRight', dragFlag: true });
              }}
            >
              <S.Rotation_Icon
                src="/assets/img/svg/bottombar/spin-right.svg"
                height="unset"
              />
            </S.Rotation_Button>
            <S.Rotation_Button
              onMouseDown={() => {
                window.SPKeyDown({ keyCode: 65 });
                spinDragStart(65);
                this.setState({ panButton: 'spinLeft', dragFlag: false });
              }}
              onMouseUp={() => {
                spinDragStop();
                this.setState({ panButton: 'spinLeft', dragFlag: true });
              }}
            >
              <S.Rotation_Icon
                height="unset"
                src="/assets/img/svg/bottombar/spin-left.svg"
              />
            </S.Rotation_Button>
          </div>
          <S.Rotation_Button
            isVertical
            onMouseDown={() => {
              window.SPKeyDown({ keyCode: 83 });
              spinDragStart(83);
              this.setState({ panButton: 'spinBottom', dragFlag: false });
            }}
            onMouseUp={() => {
              spinDragStop();
              this.setState({ panButton: 'spinBottom', dragFlag: true });
            }}
          >
            <S.Rotation_Icon
              src="/assets/img/svg/bottombar/spin-down.svg"
              width="unset"
            />
          </S.Rotation_Button>
          <S.Toggle_2D_3D style={{marginLeft: 5, marginRight: 10, backgroundColor: document.getElementById('setting_dialog') && document.getElementById('setting_dialog').style.display === 'flex' && TEXT_COLOR_NEUTRAL_5}} onClick={OpenSettings}>
            <img src='/assets/img/svg/headerbar/setting.svg' style={{height: 18}} />
          </S.Toggle_2D_3D>
        </div>
      </div>
    : !ARRAY_3D_MODES.includes(mode) ?
        <div style={{ position: 'absolute', bottom: 10, display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'flex-end' }}>
          <div style = {{ display: "flex", position: 'relative', backgroundColor: 'white', height: 70, cursor: "pointer", boxShadow : '0px 3px 5px -1px rgba(156, 154, 154, 0.2), 0px 6px 10px 0px rgba(156, 154, 154, 0.39), 0px 1px 18px 0px rgba(156, 154, 154, 0.12)', borderRadius: 10, alignItems: 'center' }}>
            <S.Toggle_2D_3D style={{backgroundColor: TEXT_COLOR_NEUTRAL_5}}>
              <S.FooterTitle style={{fontWeight: '800'}}>2D</S.FooterTitle>
            </S.Toggle_2D_3D>
            <S.Toggle_2D_3D
              style={{marginLeft: 5}}
              onClick={() => {
                projectActions.setMode(MODE_IDLE_3D);
                let layer = globalState.scene.getIn(["layers", globalState.scene.selectedLayer]);
                let sCount = layer.selected.areas.size + layer.selected.holes.size + layer.selected.items.size + layer.selected.lines.size;
                if (sCount > 1)
                  projectActions.unselectAll();
                this.setState({ ceilFlag: false });
              }}
            >
              <S.FooterTitle>3D</S.FooterTitle>
            </S.Toggle_2D_3D>
            <S.Toggle_2D_3D
              style={{marginLeft: 5}}
              onClick={() => {
                let layer = globalState.scene.getIn(["layers", globalState.scene.selectedLayer]);
                if(layer.selected.lines.size === 0){
                  alert("Select Line");
                  return;
                }
                projectActions.setMode(MODE_FRONT_VIEW);
                let sCount = layer.selected.areas.size + layer.selected.holes.size + layer.selected.items.size + layer.selected.lines.size;
                if (sCount > 1)
                  projectActions.unselectAll();
                this.props.setShowProperty(false);
                this.setState({ ceilFlag: false });
              }}
            >
              <S.FooterTitle>Front</S.FooterTitle>
            </S.Toggle_2D_3D>
            <S.Direction_Left_Right
              onClick={() => {
                this.setState({ controlButton: 'Left' });
                let value = globalState.get('viewer2D').toJS();
                value.e -= 5;
                if(value.e <= 10 && value.e + value.a * value.SVGWidth + 10 >= value.viewerWidth && value.f <= 80 && value.f + value.d * value.SVGHeight + 10 >= value.viewerHeight) {
                  viewer2DActions.updateCameraView(value);
                }
              }}
            >
              <S.ArrowIcon src="/assets/img/svg/bottombar/arrow-left.svg" />
            </S.Direction_Left_Right>
            <div>
              <S.Direction_Up_Down
                onClick={() => {
                  this.setState({ controlButton: 'Top' });
                  let value = globalState.get('viewer2D').toJS();
                  value.f -= 5;
                  if(value.e <= 10 && value.e + value.a * value.SVGWidth + 10 >= value.viewerWidth && value.f <= 80 && value.f + value.d * value.SVGHeight + 10 >= value.viewerHeight) {
                    viewer2DActions.updateCameraView(value);
                  }
                }}
              >
                <S.ArrowIcon src="/assets/img/svg/bottombar/arrow-up.svg" />
              </S.Direction_Up_Down>
              <S.Direction_Up_Down
                onClick={() => {
                  this.setState({ controlButton: 'Bottom' });
                  let value = globalState.get('viewer2D').toJS();
                  value.f += 5;
                  if(value.e <= 10 && value.e + value.a * value.SVGWidth + 10 >= value.viewerWidth && value.f <= 80 && value.f + value.d * value.SVGHeight + 10 >= value.viewerHeight) {
                    viewer2DActions.updateCameraView(value);
                  }
                }}
              >
                <S.ArrowIcon src="/assets/img/svg/bottombar/arrow-down.svg" />
              </S.Direction_Up_Down>
            </div>
            <S.Direction_Left_Right
              onClick={() => {
                this.setState({ controlButton: 'Right' });
                let value = globalState.get('viewer2D').toJS();
                value.e += 5;
                if(value.e <= 10 && value.e + value.a * value.SVGWidth + 10 >= value.viewerWidth && value.f <= 80 && value.f + value.d * value.SVGHeight + 10 >= value.viewerHeight) {
                  viewer2DActions.updateCameraView(value);
                }
              }}
            >
              <S.ArrowIcon src="/assets/img/svg/bottombar/arrow-right.svg" />
            </S.Direction_Left_Right>
            <S.ZoomButton
              onClick={() => {
                this.setState({ controlButton: 'Zoom Out' });
                let value = globalState.get('viewer2D').toJS();
                let width = value.viewerWidth;
                let height = value.viewerHeight;
                let len = value.SVGWidth;
                let _e = (width - len * value.a) / 2;
                let _f = (height - len * value.d) / 2;
                let offsetE = (value.e - _e) / value.a;
                let offsetF = (value.f - _f) / value.a;
                value.a -= 0.1; value.d -= 0.1;
                value.e -= (value.SVGWidth * value.a - value.SVGWidth * (value.a + 0.1)) / 2 ;
                value.f -= (value.SVGHeight * value.d - value.SVGHeight * (value.d + 0.1)) / 2;
                let _zoomValue = parseInt(
                  (value.a - 0.5) / constants.ZOOM_VARIABLE
                );
                if (_zoomValue > 404) return;
                if (_zoomValue < 30 || Number.isNaN(_zoomValue)) return;
                // value = zoom(value, 0, 0, 1/1.06)
                while (!(value.e <= 10)) {
                  value.e -= 0.1;
                }
                while (!(value.e + value.a * value.SVGWidth + 10 >= value.viewerWidth)) {
                  value.e += 0.1;
                }
                while (!(value.f <= 80)) {
                  value.f -= 0.1;
                }
                while (!(value.f + value.a * value.SVGHeight + 10 >= value.viewerHeight)) {
                  value.f += 0.1;
                }
                if(value.e <= 10 && value.e + value.a * value.SVGWidth + 10 >= value.viewerWidth && value.f <= 80 && value.f + value.d * value.SVGHeight + 10 >= value.viewerHeight) {
                  viewer2DActions.updateCameraView(value);
                }
              }}
            >
              <img style={{ margin: 'auto', width: 15, userSelect: 'none' }} src="/assets/img/svg/bottombar/arrow-minus.svg" />
            </S.ZoomButton>
            <S.ZoomInput
              value={`${this.state.zoomValue}%`}
              type="text"
              onChange={event => {
                let _zoomValue = parseInt(
                  event.target.value.replace('%', '')
                );
                if (_zoomValue > 380) {
                  _zoomValue = 400;
                }
                if (_zoomValue < 0 || Number.isNaN(_zoomValue)) {
                  _zoomValue = 1;
                } else {
                  this.setState({
                    zoomValue: _zoomValue
                  });
                }
              }}
              onKeyDown={e => {
                let keyCode = e.keyCode || e.which;
                if (keyCode === KEYBOARD_BUTTON_CODE.ENTER) {
                  let value = globalState.get('viewer2D').toJS();
                  let _zoomValue =
                    this.state.zoomValue * constants.ZOOM_VARIABLE + 0.5;
                  value = zoom(value, value.SVGWidth / 2, value.SVGHeight / 2, _zoomValue / value.a);
                  if (this.state.zoomValue > 404) return;
                  if (
                    this.state.zoomValue < 35 ||
                    Number.isNaN(this.state.zoomValue)
                  )
                    return;
                  if (
                    value.e <= 10 &&
                    value.e + value.a * value.SVGWidth + 10 >=
                      value.viewerWidth &&
                    value.f <= 80 &&
                    value.f + value.d * value.SVGHeight + 10 >=
                      value.viewerHeight
                  ) {
                    viewer2DActions.updateCameraView(value);
                  }
                }
              }}
              name="zoominout"
            />
            <S.ZoomButton
              onClick={() => {
                this.setState({ controlButton: 'Zoom In' });
                let value = globalState.get('viewer2D').toJS();
                let width = value.viewerWidth;
                let height = value.viewerHeight;
                let len = value.SVGWidth;
                let _e = (width - len * value.a) / 2;
                let _f = (height - len * value.d) / 2;
                let offsetE = (value.e - _e) / value.a;
                let offsetF = (value.f - _f) / value.a;
                value.a += 0.1; value.d += 0.1;
                value.e -= (value.SVGWidth * value.a - value.SVGWidth * (value.a - 0.1)) / 2;
                value.f -= (value.SVGHeight * value.d - value.SVGHeight * (value.d - 0.1)) / 2;
                let _zoomValue = parseInt(
                  (value.a - 0.5) / constants.ZOOM_VARIABLE
                );
                if (_zoomValue > 404) return;
                if (_zoomValue < 35 || Number.isNaN(_zoomValue)) return;
                // value = zoom(value, value.SVGWidth / 2, value.SVGHeight / 2, 1.06);
                if(value.e <= 10 && value.e + value.a * value.SVGWidth + 10 >= value.viewerWidth && value.f <= 80 && value.f + value.d * value.SVGHeight + 10 >= value.viewerHeight) {
                  viewer2DActions.updateCameraView(value);
                }
              }}
            >
              <img style={{ margin: 'auto', width: 18, userSelect: 'none' }} src="/assets/img/svg/bottombar/arrow-plus.svg" />
            </S.ZoomButton>
            <S.Toggle_2D_3D style={{marginLeft: 5, marginRight: 10, backgroundColor: document.getElementById('setting_dialog') && document.getElementById('setting_dialog').style.display === 'flex' && TEXT_COLOR_NEUTRAL_5}} onClick={OpenSettings}>
              <img src='/assets/img/svg/headerbar/setting.svg' style={{height: 18}} />
            </S.Toggle_2D_3D>
          </div>
        </div>
      : 
        <div style={{ position: 'absolute', bottom: 10, display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'flex-end' }}>
          <div style = {{ display: "flex", position: 'relative', backgroundColor: 'white', height: 70, cursor: "pointer", boxShadow : '0px 3px 5px -1px rgba(156, 154, 154, 0.2), 0px 6px 10px 0px rgba(156, 154, 154, 0.39), 0px 1px 18px 0px rgba(156, 154, 154, 0.12)', borderRadius: 10, alignItems: 'center' }}>
            <S.Toggle_2D_3D
              onClick={() => {
                projectActions.setMode(MODE_IDLE);
                let layer = globalState.scene.getIn(["layers", globalState.scene.selectedLayer]);
                let sCount = layer.selected.areas.size + layer.selected.holes.size + layer.selected.items.size + layer.selected.lines.size;
                if (sCount > 1)
                  projectActions.unselectAll();
                this.props.setShowProperty(true);
                this.setState({ ceil3DFlag: false });
                // document.getElementById('item_custom') && (document.getElementById('item_custom').style.display = document.getElementById('item_custom').style.display === 'flex' && 'none');
                document.getElementById('item_warning') && document.getElementById('item_custom') && (document.getElementById('item_warning').style.display = document.getElementById('item_custom').style.display === 'flex' && 'none');
              }}
            >
              <S.FooterTitle>2D</S.FooterTitle>
            </S.Toggle_2D_3D>
            <S.Toggle_2D_3D style={{backgroundColor: TEXT_COLOR_NEUTRAL_5, marginLeft: 5}}>
              <S.FooterTitle>3D</S.FooterTitle>
            </S.Toggle_2D_3D>
            <S.Toggle_2D_3D
              style={{marginLeft: 5}}
              onClick={() => {
                let layer = globalState.scene.getIn(["layers", globalState.scene.selectedLayer]);
                if(layer.selected.lines.size === 0){
                  alert("Select Line");
                  return;
                }
                projectActions.setMode(MODE_FRONT_VIEW);
                let sCount = layer.selected.areas.size + layer.selected.holes.size + layer.selected.items.size + layer.selected.lines.size;
                if (sCount > 1)
                  projectActions.unselectAll();
                this.props.setShowProperty(false);
                this.setState({ ceilFlag: false });
              }}
            >
              <S.FooterTitle>Front</S.FooterTitle>
            </S.Toggle_2D_3D>
            <S.Direction_Left_Right
              onMouseDown={() => {
                window.tDKeyDown({ keyCode: 37 });//Up key
                panDragStart(37);
                this.setState({ panButton: 'Left', dragFlag:false });

                document.addEventListener('mouseup', () => {
                  panDragStop();
                  this.setState({ panButton: 'Left', dragFlag:true });
                }, { once: true });
              }}
            >
              <S.ArrowIcon src="/assets/img/svg/bottombar/arrow-left.svg" />
            </S.Direction_Left_Right>
            <div>
              <S.Direction_Up_Down
                onMouseDown={() => {
                  window.tDKeyDown({ keyCode: 38 });//Up key
                  panDragStart(38);
                  this.setState({ panButton: 'Top', dragFlag:false });

                  document.addEventListener('mouseup', () => {
                    panDragStop();
                    this.setState({ panButton: 'Top', dragFlag:true });
                  }, { once: true });
                }}
              >
                <S.ArrowIcon src="/assets/img/svg/bottombar/arrow-up.svg" />
              </S.Direction_Up_Down>
              <S.Direction_Up_Down
                onMouseDown={() => {
                  window.tDKeyDown({ keyCode: 40 });//Up key
                  panDragStart(40);
                  this.setState({ panButton: 'Bottom', dragFlag:false });

                  document.addEventListener('mouseup', () => {
                    panDragStop();
                    this.setState({ panButton: 'Bottom', dragFlag:true });
                  }, { once: true });
                }}
              >
                <S.ArrowIcon src="/assets/img/svg/bottombar/arrow-down.svg" />
              </S.Direction_Up_Down>
            </div>
            <S.Direction_Left_Right
              onMouseDown={() => {
                window.tDKeyDown({ keyCode: 39 });//Up key
                panDragStart(39);
                this.setState({ panButton: 'Right', dragFlag:false });

                document.addEventListener('mouseup', () => {
                  panDragStop();
                  this.setState({ panButton: 'Right', dragFlag:true });
                }, { once: true });
              }}
            >
              <S.ArrowIcon src="/assets/img/svg/bottombar/arrow-right.svg" />
            </S.Direction_Left_Right>
            <S.Rotation_Button
              isVertical
              onMouseDown={() => {
                window.SPKeyDown({ keyCode: 87 });
                spinDragStart(87);
                this.setState({ panButton: 'spinTop', dragFlag: false });
              }}
              onMouseUp={() => {
                spinDragStop();
                this.setState({ panButton: 'spinTop', dragFlag: true });
              }}
            >
              <S.Rotation_Icon
                src="/assets/img/svg/bottombar/spin-up.svg"
                width="unset"
              />
            </S.Rotation_Button>
            <div>
              <S.Rotation_Button
                onMouseDown={() => {
                  window.SPKeyDown({ keyCode: 68 });
                  spinDragStart(68);
                  this.setState({ panButton: 'spinRight', dragFlag: false });
                }}
                onMouseUp={() => {
                  spinDragStop();
                  this.setState({ panButton: 'spinRight', dragFlag: true });
                }}
              >
                <S.Rotation_Icon
                  src="/assets/img/svg/bottombar/spin-right.svg"
                  height="unset"
                />
              </S.Rotation_Button>
              <S.Rotation_Button
                onMouseDown={() => {
                  window.SPKeyDown({ keyCode: 65 });
                  spinDragStart(65);
                  this.setState({ panButton: 'spinLeft', dragFlag: false });
                }}
                onMouseUp={() => {
                  spinDragStop();
                  this.setState({ panButton: 'spinLeft', dragFlag: true });
                }}
              >
                <S.Rotation_Icon
                  height="unset"
                  src="/assets/img/svg/bottombar/spin-left.svg"
                />
              </S.Rotation_Button>
            </div>
            <S.Rotation_Button
              isVertical
              onMouseDown={() => {
                window.SPKeyDown({ keyCode: 83 });
                spinDragStart(83);
                this.setState({ panButton: 'spinBottom', dragFlag: false });
              }}
              onMouseUp={() => {
                spinDragStop();
                this.setState({ panButton: 'spinBottom', dragFlag: true });
              }}
            >
              <S.Rotation_Icon
                src="/assets/img/svg/bottombar/spin-down.svg"
                width="unset"
              />
            </S.Rotation_Button>
            <S.Toggle_2D_3D style={{marginLeft: 5, marginRight: 10, backgroundColor: document.getElementById('setting_dialog') && document.getElementById('setting_dialog').style.display === 'flex' && TEXT_COLOR_NEUTRAL_5}} onClick={OpenSettings}>
              <img src='/assets/img/svg/headerbar/setting.svg' style={{height: 18}} />
            </S.Toggle_2D_3D>
          </div>
        </div>
    ;


    return ( mode !== 'MODE_VIEWING_CATALOG' ? footerview: null );
  }
}

FooterBar.propTypes = {
  state: PropTypes.object.isRequired,
  // footerbarComponents: PropTypes.array.isRequired,
  // width: PropTypes.number.isRequired,
  // height: PropTypes.number.isRequired,
  content: PropTypes.number.isRequired,
  // softwareSignature: PropTypes.string
};

FooterBar.contextTypes = {
  projectActions: PropTypes.object.isRequired,
  viewer2DActions: PropTypes.object.isRequired,
  viewer3DActions: PropTypes.object.isRequired,
  // linesActions: PropTypes.object.isRequired,
  // holesActions: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
  // translator: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,

};
